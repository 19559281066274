// Common JS for Gist New/Edit forms

import {addThrottledInputEventListener, removeThrottledInputEventListener} from '../throttled-input'
import {getAsyncCodeEditor} from '../code-editor'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onFocus} from '../onfocus'

function createEmptyFile(form: Element): Element {
  const files = form.querySelector<HTMLElement>('.js-gist-files')!
  const template = document.getElementById('js-gist-file-template') as HTMLTemplateElement
  const clone = template.content.cloneNode(true)

  files.appendChild(clone)
  const last = files.lastElementChild!

  for (const el of last.querySelectorAll('[id]')) {
    if (el.id === 'blob-dragged-file-input') {
      continue
    }
    el.removeAttribute('id')
  }

  const newTextArea = last.querySelector('.js-code-textarea')
  if (newTextArea !== null) {
    newTextArea.setAttribute('id', `blob_contents_${Date.now()}`)
  }

  return last
}

function withEmptyFile(form: Element): Element {
  for (const file of form.querySelectorAll('.js-gist-file')) {
    const filename = file.querySelector<HTMLInputElement>('.js-gist-filename')!
    const textarea = file.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
    if (!filename.value && !textarea.value) {
      return file
    }
  }
  return createEmptyFile(form)
}

// A new gist-file has been appended to the page to account for the new file.
// Wait for the editor to be ready before setting the file contents.
function withEditor(input: Element) {
  return getAsyncCodeEditor(input.closest<HTMLElement>('.js-code-editor')!)
}

async function onFilenameChange(input: HTMLInputElement | HTMLTextAreaElement) {
  const detectUrl = input.getAttribute('data-language-detection-url')
  if (!detectUrl) return

  const url = new URL(detectUrl, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('filename', input.value)
  url.search = params.toString()

  const response = await fetch(url.toString(), {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  })
  if (!response.ok) {
    const responseError = new Error()
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    responseError.message = `HTTP ${response.status}${statusText}`
    throw responseError
  }
  const data = await response.json()
  const editor = await withEditor(input)
  editor.setMode(data.language)
}

// When a user types a filename in for a gist we send it back for lang detection
//
// If we get back a language we
// 1) Set the editor mode
// 2) Update the detected language tooltip
onFocus('.js-gist-filename', async input => {
  const field = input as HTMLInputElement
  const editorContainer = input.closest<HTMLElement>('.js-code-editor')!
  await withEditor(editorContainer)
  addThrottledInputEventListener(field, onFilenameChange)
  input.addEventListener('blur', () => removeThrottledInputEventListener(field, onFilenameChange), {once: true})
})

on('click', '.js-add-gist-file', function (event) {
  event.preventDefault()
  const form = event.currentTarget.closest<HTMLElement>('.js-blob-form')!
  createEmptyFile(form).scrollIntoView()
})

on('gist:filedrop', '.js-blob-form', async function (event) {
  const {file, data} = event.detail
  const fileForm = withEmptyFile(event.currentTarget)
  const filename = fileForm.querySelector<HTMLInputElement>('.js-gist-filename')!
  filename.value = file.name
  onFilenameChange(filename)
  const editor = await withEditor(filename)
  editor.setCode(data)
  fileForm.scrollIntoView()
})

on('click', '.js-remove-gist-file', function (event) {
  event.preventDefault()
  const gistFile = event.currentTarget.closest<HTMLElement>('.js-gist-file')
  if (!gistFile) {
    return
  }

  for (const input of gistFile.querySelectorAll<HTMLInputElement>('.js-gist-deleted input')) {
    input.disabled = false
  }

  /**
   * We may want to display files that are uneditable e.g. when a gist has files that exceed its
   * size budget. We still want to be able to delete the file, so we look for the more generic js-file
   * reference when an editor isn't present.
   **/
  let fileEl = gistFile.querySelector<HTMLElement>('.js-code-editor')

  if (!fileEl) {
    fileEl = gistFile.querySelector<HTMLElement>('.js-file')!
  }

  fileEl.remove()
})

function updateRemoveButtonVisibility(context: Element) {
  const buttons = context.querySelectorAll('.js-remove-gist-file')
  for (const button of buttons) {
    /* eslint-disable-next-line github/no-d-none */
    button.classList.toggle('d-none', buttons.length < 2)
  }
}

observe('.js-remove-gist-file', function (el) {
  const context = el.closest<HTMLElement>('.js-gist-files')!
  return {
    add() {
      updateRemoveButtonVisibility(context)
    },
    remove() {
      updateRemoveButtonVisibility(context)
    },
  }
})
