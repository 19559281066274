import {remoteForm} from '@github/remote-form'

// Task list changes trigger file updates. Once the remote call has completed, we need to update the
// form action and CSRF token to reflect the current file blob OID.
remoteForm('.js-gist-file-update-container .js-comment-update', async function (form, wants) {
  let response
  try {
    response = await wants.json()
  } catch (error) {
    // Ignore the non 200 status response
    return
  }
  form.action = response.json.url
  if (response.json.authenticity_token) {
    // TODO: Avoid changing form's action, use another form instead
    // eslint-disable-next-line github/authenticity-token
    const input = form.querySelector<HTMLInputElement>('input[name=authenticity_token]')!
    input.value = response.json.authenticity_token
  }
})
